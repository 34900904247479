import { DevopsAuditFuncRepo } from "./devopsAuditFuncRepo"
import { ReportsRepo } from "./reportsRepo"

const repositories = {
    "devopsauditfunction": new DevopsAuditFuncRepo(),
    "reports": new ReportsRepo()
}

export class RepoFactory{
    static getRepo(repoName){
        return repositories[repoName.toString().toLowerCase()];
    }
}