<template>
    <b-container fluid>
        Token: 
        <div>{{authToken}}</div>
    </b-container>
</template>

<script>
    export default {
        name: "Lists",
        data: function () {
            return {
                authToken: ""
            };
        },
        async created(){
            this.authToken = await this.$auth.getAccessTokenAsync("api://fa267570-ea47-4672-b70c-4c6279c4d218/.default");
        }
    };
</script>

<style>
</style>