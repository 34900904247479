import * as msal from "@azure/msal-browser";

let authInstance;

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);

function createAuthObject(Vue, {onRedirectCallback = DEFAULT_REDIRECT_CALLBACK, msalConfig, ...options}){
        if(!authInstance) {
            authInstance = new Vue({
                data(){
                    return {
                        loading: true,
                        user: {
                            loggedIn: false,
                            claims: []                    
                        },
                        msalInstance: new msal.PublicClientApplication(msalConfig)
                    }
                },
                methods: {
                    resetData: function(){
                        this.user = {                            
                            loggedIn: false
                        };
                    },
                    redirectToLogin({targetPage}){
                        this.msalInstance.loginRedirect({
                            scope: msalConfig.auth.scope,
                            state: JSON.stringify({
                                targetUrl: targetPage
                            })
                        });
                    },
                    getUserDisplayName(){
                        return this.user.claims.name;
                    },
                    async getAccessTokenAsync(scope){
                        var tokenResponse = await this.msalInstance.acquireTokenSilent({
                            scopes: [scope],
                            account: this.user.activeAccount
                        });
                        return tokenResponse.accessToken;
                    },
                    logout(){
                        this.msalInstance.logout({
                            //postLogoutRedirectUri: "https://google.com"
                        });
                    }
                },
                async created(){
                    this.loading = true;
                    try {
                        var response = await this.msalInstance.handleRedirectPromise();
                        var accounts = this.msalInstance.getAllAccounts();
                        if (accounts.length > 0) {
                            this.user.activeAccount = accounts[0];
                            this.user.name = accounts[0].username;
                            this.user.loggedIn = true;
                            this.user.claims = response.idTokenClaims ?? [];
                            onRedirectCallback(JSON.parse(response.state));
                        }
                    } catch (ex) {
                        this.resetData();
                        console.log("ERROR", ex);
                    } finally {
                        this.loading = false;
                    }
                }
            });
        }
        return authInstance;
      };

      
export const AuthPlugin = {
    install(Vue, options) {
      Vue.prototype.$auth = createAuthObject(Vue, options ?? {});
    }
};

export const getInstance = () => authInstance;