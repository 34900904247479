import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: null
  },
  mutations: {
    setLoadingData(state, data){
      state.loading = data;
    }
  },
  actions: {
    showLoadingOverlay(context, data){
      if(typeof data === "string"){
        context.commit("setLoadingData", { text: data } );
      }else{
        context.commit("setLoadingData", data);
      }
    },
    hideLoadingOverlay(context){
      context.commit("setLoadingData", null);
    }
  },
  modules: {
  }
})
