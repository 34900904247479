import axios from "axios";
import { getInstance } from "../auth/auth"

export class BaseRepo{

    constructor(baseUrl, scope){
        this.baseUrl = baseUrl;
        this.scope = scope;
    }

    async getClientAsync(){
        var authInstance = getInstance();
        var authToken = await authInstance.getAccessTokenAsync(this.scope);
        return axios.create({
            baseURL: this.baseUrl,
            headers: {
                "Authorization": `Bearer ${authToken}`
            }
        });
    }

}