import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import { authGuard } from "../auth/authGuard";
import LoggedOut from "../views/pages/LoggedOut";
import DevOpsAudit from "../views/pages/DevOpsAudit";
import AuditResult from "../views/pages/AuditResult";
import Lists from "../views/pages/Lists";

Vue.use(VueRouter)

function authorized(route) {
  return { ...route, beforeEnter: authGuard };
}

function anonymous(route) {
  return route;
}

// route level code-splitting
// this generates a separate chunk (about.[hash].js) for this route
// which is lazy-loaded when the route is visited.
// component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
const routes = [
  authorized({
    path: '/',
    name: 'Home',
    component: Home
  }),
  authorized({
    path: '/devopsaudit',
    name: 'DevopsAudit',
    component: DevOpsAudit
  }),
  authorized({
    path: '/lists',
    name: 'Lists',
    component: Lists
  }),  
  authorized({
    path: '/auditresult',
    name: 'AuditResult',
    component: AuditResult
  }),
  anonymous({
    path: "/loggedout",
    name: "LoggedOut",
    component: LoggedOut
  })
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router;
