<template>
  <b-card>
      <b-card-title>How to run this process?</b-card-title>
      <b-card-text>
          <ol>
              <li>Open admin fetcher project in Visual Studio Code.</li>
              <li>Open a new terminal and run "npm run serve".</li>
              <li>Once the login page opens, enter the credentials for the svc_secops_audit account.</li>
              <li>Let the process run until the browser closes itself. If for some reason this gets stuck start the process again.</li>
              <li>Once the process is finished it will create 2 files: result.json and report.txt</li>
              <li>Report .txt includes the teams with ex employees and teams with no admins. Submit this to governence email address if there are any teams without admins.</li>
              <li><strong>If there are any teams without admins, wait a week for the governence team to assign them one and come back to this step in a week.</strong></li>
              <li>Copy the contents of the result.json to projects field on the left.</li>
              <li>Enter an appropriate name for the audit reference (This might be displayed to users that we will email).</li>
              <li>Finally click submit and this should run the audit process and send emails to DevOps admins and the governence team.</li>
          </ol>
      </b-card-text>
  </b-card>
</template>

<script>
export default {
  name: 'AzureDevOpsInstructions',
  props: {
    msg: String
  }
}
</script>