import axios from "axios";
import {BaseRepo} from "./baseRepo"

export class DevopsAuditFuncRepo extends BaseRepo{

    constructor(){
        super(process.env.VUE_APP_DEVOPS_FUNCTION_URL_BASE, "api://fd30e237-1fcd-4953-9776-2fc117883544/.default");
    }

    async startAuditAsync(auditSettings){
        let client = await this.getClientAsync();
        return client.post("/api/AzureDevOpsAuditFunction", auditSettings, {
            timeout: 900000 //15mins
        });
    }

}