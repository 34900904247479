import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { AuthPlugin } from "./auth/auth"
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.config.productionTip = false

Vue.use(AuthPlugin, {
  onRedirectCallback: function(state){
    router.push(state && state.targetUrl ? state.targetUrl : window.location.pathname);
  },
  msalConfig: {
    auth: {
        clientId: "a0a470e4-5303-4798-912a-a95300c2b64e",
        authority:"https://login.microsoftonline.com/560ec2b0-df0c-4e8c-9848-a15718863bb6",
        redirectUri: `${window.location.protocol}//${window.location.hostname}${(window.location.port != 80)? ":" + window.location.port : ""}/`,
        scope: "api://a0a470e4-5303-4798-912a-a95300c2b64e/.default"
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
