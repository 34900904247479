<template>
    <b-container fluid>
        <h4>Audit Results</h4>
        <p>You can select a report from the select below and click download to get it's results.</p>
        <b-col>
            <b-row>
                <b-select v-model="selectedReport" :options="availableReports" class="report-select mr-1">
                </b-select>
                <b-btn @click="download()">Download</b-btn>                
            </b-row>    
        </b-col>       
    </b-container>
</template>

<script>

import axios from "axios"
import {RepoFactory} from "./../../repos/repoFactory"

export default {
    name: "AuditResult",
    data: function () {
        return {
            selectedReport: "MicrosoftTeamsLatestAudit",
            availableReports: [
                { value: 'MicrosoftTeamsLatestAudit', text: 'Last Microsoft Teams Audit Results' },
                { value: 'AzureDevOpsLatestAudit', text: 'Last Azure DevOps Audit Results' },
            ]
        }
    },
    methods: {
        download: async function () {
            try{
                this.$store.dispatch("showLoadingOverlay", {text: "Downloading..."});
                var reports = RepoFactory.getRepo("reports");
                let result = await reports.getResult(this.selectedReport);
                var fileURL = window.URL.createObjectURL(new Blob([result.data]));
                var fileLink = document.createElement('a');     
                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'results.csv');
                document.body.appendChild(fileLink);
                fileLink.click();
            }catch(ex){
                if(ex.toString().indexOf("Request failed with status code 401") != -1){
                    alert("You must be logged into complete this action.");
                }else if(ex.toString().indexOf("Request failed with status code 403") != -1){
                    alert("You don't have the sufficient permission to carry out this action.");
                }else{
                    alert(`An error has occurred:\r\n\r\n${ex.toString()}\r\n\r\nPlease try again later or contact SecOps development team if you need this issue resolved immediately.`);
                }
            }finally{
                this.$store.dispatch("hideLoadingOverlay");
            }
        }
    }
};
</script>

<style>
    .report-select {
        width: 300px;        
    }
</style>