import axios from "axios";
import {BaseRepo} from "./baseRepo"

export class ReportsRepo extends BaseRepo{

    constructor(){
        super(process.env.VUE_APP_COREAPI_BASE, "api://fa267570-ea47-4672-b70c-4c6279c4d218/.default");
    }

    async getResult(reportId){
        let client = await this.getClientAsync();
        return client.post("/reports/" + reportId + "/", {});
    }

}