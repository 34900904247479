<template>
    <b-container fluid>
        <h4>Azure DevOps Audit</h4>
        <p>
            Please fill the following form and hit start to execute the Azure
            DevOps auditing process. Once this process is started it cannot be
            cancelled. If any errors occur, please contact the SecOps Dev team
            to investigate the cause of the error before attempting to start the
            process again.
        </p>
        <b-col>
            <b-row>
                <b-col md="12" lg="8">
                    <b-row no-gutters>
                        <b-form @submit="onSubmit" class="w-100">
                            <b-card>
                                <b-card-text>
                                    <b-form-group
                                        id="input-group-1"
                                        label="Audit Reference:"
                                        label-for="input-1"
                                        description="Please select a unique name that can later be used to reference this audit. Also please keep in mind that this reference might be displayed to DevOps admins."
                                    >
                                        <b-form-input id="input-1" v-model="auditSettings.auditReference" type="text" placeholder="ex: DevOps Audit For Quarter 1 of 2020"></b-form-input>
                                        <b-form-invalid-feedback :state="false" v-if="!validationResult.valid && !validationResult.auditReference.valid"> 
                                            {{validationResult.auditReference.error}}
                                        </b-form-invalid-feedback>
                                    </b-form-group>

                                    <b-form-group id="input-group-4">
                                        <b-form-checkbox v-model="auditSettings.sendReviewEmails">Send Review Email to DevOps Admins</b-form-checkbox>
                                    </b-form-group>
                                    <b-form-group id="input-group-4">
                                        <b-form-checkbox disabled v-model="auditSettings.sendReportEmail">Send Summary to Governence Team</b-form-checkbox>
                                    </b-form-group>

                                    <b-form-group label="Projects:" label-for="textarea-1">
<textarea placeholder='{
    "organizationName": "Aptean",
    "projectName": "Test",
    "admins": [
        {
            "displayName": "John Doe",
            "mail": "john.doe@aptean.com",
            "aadId": "be2111fc-2d51-4007-bc8a-faaacfbd1d61"
        },
        ...
    ]
    ...
}' style="resize: none" class="form-control" id="textarea-1" :value="projectsString" @input="changeProjects($event)" rows="13"></textarea>                                   
                                        <b-form-invalid-feedback :state="false" v-if="!validationResult.valid && !validationResult.projectJson.valid"> 
                                            {{validationResult.projectJson.error}}
                                        </b-form-invalid-feedback>
                                    </b-form-group>

                                </b-card-text>
                                <template v-slot:footer>
                                    <div class="text-right">
                                        <b-button class="pull-right" type="submit" variant="primary">Submit</b-button>
                                    </div>
                                </template>
                            </b-card>
                        </b-form>
                    </b-row>
                </b-col>
                <b-col sm="12" md="12" lg="4">
                   <AzureDevOpsInstructions></AzureDevOpsInstructions>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="12" lg="8" class="ml-auto mr-auto">
                    <b-card class="mt-3 w-100 " header="JSON">
                        <pre class="m-0">{{ auditSettings }}</pre>
                    </b-card> 
                </b-col>
            </b-row>
        </b-col>       
    </b-container>
</template>

<script>

import axios from "axios"
import {RepoFactory} from "./../../repos/repoFactory"
import AzureDevOpsInstructions from "../../components/AzureDevOpsInstructions"

export default {
    name: "DevopsAudit",
    data: function () {
        return {
            auditSettings: {
                sendReviewEmails: true,
                sendReportEmail: true,
            },
            projectsString: "",
            projectStringIsValidJson: false,
            validationResult: {
                valid: true,
                auditReference: {valid: true},
                projectJson: {valid: true}
            }
        };
    },
    methods: {
        onSubmit: async function (e) {
            e.preventDefault();
            this.validate();
            if(this.validationResult.valid){
                var result;
                try{
                    this.$store.dispatch("showLoadingOverlay", {text: "This may take a while. Please do not close this tab.\r\nProcessing..."});
                    var devopsFuncRepo = RepoFactory.getRepo("devopsauditfunction");
                    await devopsFuncRepo.startAuditAsync(this.auditSettings);
                    alert("Started Azure DevOps audit process successfully.");
                }catch(ex){
                    if(ex.toString().indexOf("Request failed with status code 401") != -1){
                        alert("You must be logged into complete this action.");
                    }else if(ex.toString().indexOf("Request failed with status code 403") != -1){
                        alert("You don't have the sufficient permission to carry out this action.");
                    }else{
                        alert(`An error has occurred:\r\n\r\n${ex.toString()}\r\n\r\nPlease try again later or contact SecOps development team if you need this issue resolved immediately.`);
                    }
                }finally{
                    this.$store.dispatch("hideLoadingOverlay");
                }

            }
        },
        changeProjects(e){
            try{
                this.projectsString = e.target.value;
                this.auditSettings.projects = JSON.parse(e.target.value);
                this.projectStringIsValidJson = true;
            }catch{  
                this.projectStringIsValidJson = false;
            }
        },
        validate(){
            var result = {valid: true};
            if(!this.auditSettings.auditReference){
                result.auditReference = {valid: false, error: "Audit reference must be supplied."}    
                result.valid = false;            
            }else if(this.auditSettings.auditReference.length > 256){
                result.auditReference = {valid: false, error: "Audit reference must be shorter than 256 characters."}
                result.valid = false;
            }else if(/^\s*$/.test(this.auditSettings.auditReference)) {
                result.auditReference = {valid: false, error: "Audit reference cannot be just whitespace."}
                result.valid = false;
            }else{
                result.auditReference = {valid: true}
            }

            if(!this.projectStringIsValidJson){ 
                result.projectJson = {valid: false, error: "This field must contain valid JSON."}
                result.valid = false;
            }else{
                result.projectJson = {valid: true}
            }
            
            this.validationResult = result;
            console.log(result);
        }
    },
    components: {
        AzureDevOpsInstructions
    }
};
</script>

<style>
</style>