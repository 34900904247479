<template>
    <b-container fluid class="home" style="text-align: center">
        <strong>Welcome to the SecOps dashboard, this dashboard is intended for configuration, execution and tracking of internal security related processes.</strong>
    </b-container>
</template>

<script>
export default {
    name: "Home"
};
</script>
